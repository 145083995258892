@media only screen and (max-width: 767px) {
    .first-gtand11-res{
        display: flex;
        align-items: baseline;
        justify-content: center;
    }
    section.first-sec{
        padding-top: 40px;
    }
    #firstimgDiv{
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .sticky {
        width: 100%;
        margin: 0 auto;
        height: 50px;
        top: 0;
        overflow: hidden;
    }
    .left-right-sticky-section .-part-divi{

    }
    .stickybg-secton, .img-div.secd-tv-img{
        height: 100%;
    }
    .stickybg-secton img, .stickybg-secton picture{
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    .stickybg-secton::before{
        display: none;
    }
    .kodak-center-part{
        width: 100%;
        margin-top: 40px;
    }
    .second-stick-part {
        flex-direction: column-reverse;
        margin-top: 20px;
    }
    section.left-right-sticky-section {
        margin-top: 30px;
    }
    .spect-frame > div{
        padding: 10px;
    }
    .third-sec-grid .container{
        padding-top: 50px;
    }
    .section-space{
        padding: 20px 0 0 0;
    }
    .common-tv-imgmain-div, .main-img-commom .video-part{
        top: 5%;
        left: 5%;
    }
    .third-sec-img-div{
        width: 100%;
    }
    .discover-tv-img{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .owl-slide-applogo{
        height: 150px;
    }
}