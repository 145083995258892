@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body{
  font-family: 'Roboto', sans-serif;
}

html{
  scroll-behavior: smooth;
}
body {
  background-image: url(./assets/img/bg.webp);
  width: 100%;
  height: 100%;
  background-position: center;
  /* background-repeat: repeat-y; */
  background-size: 100%;
}

/* loader */
.lodingdiv {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: #FFF;
}
.wrap {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}
.text {
 color: #fbae17;
 display: inline-block;
 margin-left: 5px;
}
.bounceball {
 position: relative;
 display: inline-block;
 height: 37px;
 width: 15px;
}
.bounceball:before {
 position: absolute;
 content: '';
 display: block;
 top: 0;
 width: 15px;
 height: 15px;
 border-radius: 50%;
 background-color: #fbae17;
 transform-origin: 50%;
 animation: bounce 500ms alternate infinite ease;
}
@keyframes  bounce {
 0% {
   top: 30px;
   height: 5px;
   border-radius: 60px 60px 20px 20px;
   transform: scaleX(2);
}
 35% {
   height: 15px;
   border-radius: 50%;
   transform: scaleX(1);
}
 100% {
   top: 0;
}
}

/* common */
.overflow-hidden{
  overflow: hidden;
}
.section-space{
  padding: 60px 0;
}

/* first section */
.frame-upper {
  position: relative;
  width: 600px;
  height: 300px;
  margin: 0 auto;
}
.frame-upper img{
  width: 450px;
  position: absolute;
  top: 30px;
  left: 80px;
  border-radius: 10px;
  z-index: 2;
}

.img-div.first-gtv-img {
  position: relative;
}
.main-first-img {
  position: absolute;
  top: 11%;
  left: 8%;
  width: 85%;
  border-radius: 10px;
  overflow: hidden;
}

/* second section */
.second-section{
  /* display: flex;*/
  /* overflow-x: hidden; */
  padding: 10px;
  height: 100%;
}
.kodak-left-img-sticky{
  width: 200px;
  float: left;
  height: 100vh;
  background-color: #000;
  padding: 10px;
}

.kodak-left-img-sticky .random-left{
  width: 100%;
  height: 300px;
  background-color: gray;
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
}
.kodak-center-part{
  width: calc(100% - 320px);
}
.h-100vh{
  height: 200vh;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 25px;
  width: 300px;
  height: 105vh;
}
.stickybg-secton{
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #fab636;
  border-radius: 10px;
}
.stickybg-secton::before {
  content: "";
  position: absolute;
  width: calc(100% + 30px);
  height: calc(100% + 38px);
  background-image: url(./assets/img/second-sec/border-img.png);
  background-size: 4% 50%;
  background-position: 100% 100%;
  background-repeat: repeat;
  top: -15px;
  left: -15px;
  z-index: -1;
  border-radius: 10px;
}
.stickybg-secton img, .stickybg-secton picture{
  width: 100%;
  height: 100vh;
  object-fit: contain;
  object-position: center;
}

/* third section */
.third-sec-grid .container{
  padding-top: 100px;
}
.third-sec-img-div{
  width: 90%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.third-sec-grid .col-4:nth-child(even) .third-sec-img-div{
  width: 60%;
  margin: 0 auto;
  margin-top: -40px;
}

.third-sec-grid .col-4:nth-child(odd) .third-sec-img-div{
  width: 90%;
  margin: 0 auto;
  padding: 10px 10px;
}
.third-sec-grid .col-4:nth-child(11) .third-sec-img-div{
  margin-top: -100px;
}
.third-sec-grid .col-4:nth-child(7) .third-sec-img-div{
  margin-top: -100px;
}
.third-sec-grid .col-4:nth-child(9) .third-sec-img-div{
  margin-top: -100px;
}
.third-sec-grid .col-4:nth-child(13) .third-sec-img-div{
  margin-top: -80px;
}


/* ott move */
.ott-full-move-bg{
  background-image: url(./assets/img/ottfull.webp);
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: 0;
  background-repeat: repeat-x;
  animation: slide 20s linear infinite;
}
@keyframes slide {
  0% {
    background-position: 0;
  }
  100% {
    background-position: -2400px; /* The image width */
  }
}

/*  */
.common-tv-imgmain-div {
  width: 100%;
  /* height: 100vh; */
}
.common-tv-imgmain-div img.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.spect-frame {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.spect-frame > div {
  width: 45%;
  padding: 20px 20px;
}
.main-img-commom{
  position: relative;
}
.common-tv-imgmain-div{
  position: absolute;
  top: 40px;
  left: 40px;
  width: 90%;
  height:  90%;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
}
.main-img-commom .video-part{
  position: absolute;
  top: 40px;
  left: 40px;
  width: 90%;
  height:  90%;
  z-index: 2;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
}
.main-img-commom .video-part video{
  border-radius: 10px;
  overflow: hidden;
}

/* .img-video-common-part{
  position: relative;
}
.img-video-common-part .video-part{
  position: absolute;
  top: 25px;
  left: 50px;
  width: 89.5%;
  height: 89.5%;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
} */
.play-pause-div {
  position: absolute;
  bottom: 5%;
  right: -6%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.play-pause-div svg {
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  position: absolute;
  z-index: 9;
}


.circle{
  position: absolute;
  width: 200px;
  height: 200px;
  background: #fab636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle .text{
  width: 100%;
  height: 100%;
  position: absolute;
  animation: rotateText 10s linear infinite;
}
@keyframes rotateText {
  0%{
    transform: rotate(360deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
.circle .text span{
  position: absolute;
  left: 50%;
  font-size: 1.2rem;
  transform-origin: 0 100px;
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}



/* qled */
.qled .main-p{
  width: 100%;
  height: 100vh;
  background-color: teal;
}
.qled .main{
  width: 100%;
  height: 100vh;
  background-color: aquamarine ;
  position: relative;
}

.qled .top{
  width: 100%;
  height: 50vh;
  background-color: #fcb900;
  position: absolute;
  top:  0;
  overflow: hidden;
}
.qled .main h1{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  font-size: 22vw;
  font-weight: 900;
  color: #000;
}
.qled .center{
  width: 100%;
  height: 100%;
  background-color: #000;
}
.qled .center .content{
  padding-top: 100px;
  height: 100%;
  background-color: #000;
}

.qled .bottom{
  width: 100%;
  height: 50vh;
  background-color: #fcb900;
  position: absolute;
  bottom: 0;
  overflow: hidden; 
}
.qled .main h1.top-h1{
  bottom: -50%;
}
.qled .main h1.bottom-h1{
  top: -52%;
}


/*  */
.owl-slide-applogo {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.owl-slide-applogo img {
    width: 90% !important;
    object-fit: contain;
    object-position: center;
    height: 100%;
}